import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DomainSharedService {
    private selectedDomainSource = new BehaviorSubject<string>('');
    currentSelectedDomain = this.selectedDomainSource.asObservable();

    constructor() {}

    setSelectedDomain(domain: string): void {
        console.log('DomainSharedService', domain);
        this.selectedDomainSource.next(domain);
    }

    getSelectedDomain(): string {
        return this.selectedDomainSource.getValue();
    }
}